<template>
  <div class="add-results-container">
    <div class="container">
      <div class="title">
        <span class="my-title">我的成果</span>
        <Input
          suffix="ios-search"
          placeholder="输入标题"
          style="width: auto"
          v-model="titles"
          @click.native="handleSearch"
        />
        <input type="text" class="elHidden">
      </div>
      <div class="close" @click="hideResult">X</div>
      <ul class="results">
          <CheckboxGroup v-model="groupList">
            <li v-for="item in articleList" :key="item.resultId">
              <Checkbox :label="JSON.stringify(item)">{{ item.resultName }}</Checkbox>
            </li>
          </CheckboxGroup>
      </ul>
      <div class="footer">
        <Page :total="total" :page-size="5" @on-change="handleChange" />
        <span>
          <Button shape="circle" type="primary" ghost @click="hideResult"
            >取消</Button
          >
          <Button shape="circle" type="primary" @click="handleSave" v-throttleClick="2000"
            >确认</Button
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    resultList: {
      type: Array,
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      groupList: [],
      articleList: [],
      titles: "",
      param: {},
      total: 0,
    };
  },
  mounted() {
    this.groupList = this.resultList;
  },
  created() {
    this.param = {
      createUser: this.userInfo.user.id,
      pageNo: 1,
      pageSize: 5,
    };
    this.getUserArticleList({
      ...this.param,
    });
  },
  methods: {
    hideResult() {
      this.groupList = [];
      // this.$emit("resultSave", this.groupList);
      this.$emit("hideResult");
    },
    handleSave() {
      if (this.groupList.length === 0) {
        this.$Message.error("成果不能为空");
        return;
      }
      this.$emit("resultSave", this.groupList);
      this.$emit("hideResult");
      this.groupList = [];
    },
    async getUserArticleList(data) {
      const resp = await this.$apis.circleServe.getUserArticleList(data);
      this.articleList = resp.data.list.map((item) => {
        return {
          resultName: item.title,
          type: 2,
          articleType: item.type,
          resultId: item.articleId,
          resultType: item.articleType,
        };
      });
      this.total = +resp.data.total;
    },
    handleSearch() {
      this.getUserArticleList({
        ...this.param,
        title: this.titles,
      });
    },
    handleChange(page) {
      this.param.pageNo = page;
      this.getUserArticleList(this.param);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-results-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  .container {
    width: 800px;
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 24px;
      color: #333333;
      .my-title {
        vertical-align: middle;
        margin-right: 25px;
      }
      .elHidden {
        width:0;
        opacity:0;
      }
      &::v-deep .ivu-input {
        background: #fefefe;
        border: 1px solid #00a4ff;
        border-radius: 15px;
        width: 300px;
        height: 30px;
      }
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background: #00a4ff;
      border-radius: 0px 18px 0px 18px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
    }
  }
  .results {
    min-height: 200px;
    padding: 40px 0;
    li {
      margin-bottom: 17px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-right: 20px;
    }
  }
}
</style>
