<template>
  <div class="add-picture-container">
    <div class="container">
      <div class="title">添加图片</div>
      <div class="close" @click="hidePicture">X</div>
      <div class="picture">
        <div
          class="demo-upload-list"
          v-for="(item, index) in uploadList"
          :key="index"
        >
          <img v-lazy="$global.imgUrl + item.accessPath" alt="" />
          <div class="demo-upload-list-cover">
            <Icon
              type="ios-trash-outline"
              @click.native="handleRemove(index)"
            ></Icon>
          </div>
        </div>
        <Upload
          ref="upload"
          accept=".png, .jpg, .jpeg"
          :show-upload-list="false"
          :default-file-list="defaultList"
          :on-success="handleSuccess"
          :format="['jpg', 'jpeg', 'png']"
          :max-size="2048"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :action="actionUrl"
          :headers="{ token: token }"
          type="drag"
          style="width: 171px; display: inline-block"
          :before-upload="handleBeforeUpload"
        >
          <div style="width: 171px; height: 171px; line-height: 171px">
            <Icon type="ios-camera" size="20"></Icon>
          </div>
        </Upload>
      </div>
      <div class="footer">
        <span class="num">已添加：{{ uploadList.length }}/9</span>
        <span>
          <Button shape="circle" type="primary" ghost @click="hidePicture"
            >取消</Button
          >
          <Button shape="circle" type="primary" @click="handleSave" v-throttleClick="2000"
            >确认</Button
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    cover: {
      type: String,
    },
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
      defaultList: [
        {
          name: "a42bdcc1178e62b4694c830f028db5c0",
          url: "https://tse1-mm.cn.bing.net/th?id=OIP-C.UJn6UIedh0fIZk5V4f_5zgHaE8&w=145&h=100&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2",
        },
      ],
      imgName: "",
      uploadList: [],
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
    };
  },
  mounted() {
    this.uploadList = [...this.dataList];
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    handleRemove(index) {
      this.uploadList.splice(index, 1);
    },
    handleSuccess(res) {
      this.uploadList.push({
        accessPath:res.content.url,
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "The file format is incorrect",
        desc:
          "File format of " +
          file.name +
          " is incorrect, please select jpg or png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 2M.",
      });
    },
    handleBeforeUpload() {
      const check = this.uploadList.length < 9;
      if (!check) {
        this.$Notice.warning({
          title: "上传图片已达上限",
          duration: 1.5
        });
      }
      return check;
    },
    hidePicture() {
      this.uploadList = [];
      this.$emit("hidePicture");
    },
    handleSave() {
      if (this.uploadList.length === 0) {
        this.$Message.error("图片不能为空");
        return;
      }
      this.$emit("pictureSave", this.uploadList);
      this.$emit("hidePicture",false);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-picture-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  .container {
    width: 1000px;
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    height: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 24px;
      color: #333333;
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background: #00a4ff;
      border-radius: 0px 18px 0px 18px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
    }
  }
  .picture {
    min-height: 450px;
    padding: 40px 0;
    .demo-upload-list {
      display: inline-block;
      width: 171px;
      height: 171px;
      text-align: center;
      line-height: 171px;
      border: 1px solid transparent;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      margin-right: 4px;
    }
    .demo-upload-list img {
      width: 100%;
      height: 100%;
    }
    .demo-upload-list-cover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover {
      display: block;
    }
    .demo-upload-list-cover i {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      margin: 0 2px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .num {
      font-size: 18px;
      color: #00a4ff;
    }
    button {
      margin-right: 20px;
    }
  }
}
</style>
