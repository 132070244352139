<template>
  <div class="discuss-details-container">
    <div class="top">
      <div class="head">
        <div class="left">
          <img
            v-if="updateObject.createUserHeadImg"
            style="cursor: pointer"
            @click="goAuther(updateObject)"
            :src="$global.imgUrl + updateObject.createUserHeadImg"
            alt=""
          />
          <img 
            v-else 
            @click="goAuther(updateObject)" 
            style="cursor: pointer" 
            :src="require('@/assets/imgs/head_bg.png')"/>
          <div class="info">
            <span
              style="cursor: pointer"
              @click="goAuther(updateObject)"
              v-if="updateObject.createUserName"
              >{{ updateObject.createUserName }}</span
            >
            <div class="update">{{ updateObject.unit }}</div>
          </div>
        </div>
        <div class="right">
          <span v-if="updateObject.createDate"
            >{{ updateObject.createDate | dayFormate }}发布于圈子</span
          >-
          <span class="project" @click="goCircle">{{
            updateObject.circleName
          }}</span>
        </div>
      </div>
      <div class="title">{{ updateObject.title }}</div>
      <div class="content">
        {{ updateObject.content }}
      </div>
    </div>
    <div class="form">
      <div class="add-discuss">添加回复</div>
      <div class="box">
        <Input :disabled="isDisabled" type="textarea" v-model="contents"></Input>
      </div>
      <div class="pi-box">
        <div class="results-box">
          <div
            class="title"
            @click="goDetails(v)"
            v-for="(v, i) in resultList1"
            :key="i"
          >
            {{ v.resultName }}
          </div>
        </div>
        <div
          class="demo-upload-list"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <img v-lazy="$global.imgUrl + item.accessPath" alt="" />
          <div class="demo-upload-list-cover">
            <Icon
              type="ios-trash-outline"
              @click.native="handleRemove(index)"
            ></Icon>
          </div>
        </div>
      </div>
      <div class="btns">
        <div class="left">
          <Button type="dashed" shape="circle" @click="handlePicture"
            >添加图片</Button
          >
          <Button type="dashed" shape="circle" @click="handleResult"
            >添加成果</Button
          >
        </div>
        <div class="right">
          <Button
            type="primary"
            shape="circle"
            @click="handleSubmit"
            v-throttleClick="2000"
            >发布</Button
          >
        </div>
      </div>
    </div>
    <div class="main">
      <ul class="nav">
        <li>
          <router-link
            :to="{ path: '/replydetail', query: { id: $route.query.id } }"
            >回复</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              path: '/replydetailachievements',
              query: { id: $route.query.id },
            }"
            >成果</router-link
          >
        </li>
      </ul>
      <router-view></router-view>
    </div>
    <AddPicture
      v-if="showPicture"
      @hidePicture="showPicture = false"
      @pictureSave="pictureSave"
      :dataList="dataList"
    ></AddPicture>
    <AddResults
      v-if="showResult"
      @hideResult="showResult = false"
      @resultSave="resultSave"
      :resultList="resultList"
    ></AddResults>
  </div>
</template>

<script>
import AddPicture from "../components/AddPicture.vue";
import AddResults from "../components/AddResults.vue";
import { mapState } from "vuex";
export default {
  components: {
    AddPicture,
    AddResults,
  },
  data() {
    return {
      updateObject: {},
      showPicture: false,
      showResult: false,
      dataList: [],
      resultList: [],
      resultList1: [],
      contents: "",
      tempList: [],
      isDisabled:false,
    };
  },
  computed: {
    ...mapState(["hasLogin", "userInfo"]),
  },
  created() {
    this.discussDetail({
      type: 1,
      id: this.$route.query.id,
    });
  },
  mounted() {
    this.$bus.$on("mainDocument", this.handleDocument);
    if(!this.hasLogin){
      this.isDisabled = true;
    }
  },
  methods: {
    goAuther(v) {
      console.log(v);
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: { id: v.createUserId },
      });
      window.open(url.href, "_blank");
    },
    // 点击成果跳转
    goDetails(item) {
      let url;
      if (item.resultType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "paperDetail",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "conferencepapers",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "conferenceppt",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "conferenceother",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        }
      } else if (item.resultType === 1) {
        url = this.$router.resolve({
          path: "patentdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 2) {
        url = this.$router.resolve({
          path: "monographdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 3) {
        url = this.$router.resolve({
          path: "reportdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 4) {
        url = this.$router.resolve({
          path: "standarddetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "informationdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    // 删除图片
    handleRemove(index) {
      this.dataList.splice(index, 1);
    },
    pictureCencel(bool) {
      if (bool) {
        this.showPicture = false;
        this.dataList = [];
      } else {
        this.showPicture = false;
      }
    },
    goCircle() {
      let url = this.$router.resolve({
        path: "/circledetails",
        query: {
          id: this.updateObject.circleId,
        },
      });
      window.open(url.href, "_blank");
    },
    handlePicture() {
      if (this.hasLogin) {
        this.showPicture = true;
      } else {
        this.$store.commit("showAlert", true);
        return;
      }
    },
    handleResult() {
      if (this.hasLogin) {
        this.showResult = true;
      } else {
        this.$store.commit("showAlert", true);
        return;
      }
    },
    async handleSubmit() {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return
      }
      if (this.userInfo.user.customerType !== 2) {
        this.$Message.error("当前是未认证的账号，请先去认证");
        return;
      }
      if (this.contents === "") {
        this.$Message.error("内容不能为空");
        return;
      }
      if (this.contents.length > 1000) {
        this.$Message.error("内容数量已达上限");
        return;
      }
      const resp = await this.$apis.circleServe.addReply({
        circleId: this.updateObject.circleId,
        content: this.contents,
        discussId: this.updateObject.id,
        files: this.dataList.concat(this.resultList1),
      });
      if (resp.data.resultCode === "200") {
        this.$Message.success(resp.data.resultDesc);
        this.contents = "";
        this.dataList = [];
        this.resultList1 = [];
        this.resultList = [];
      } else {
        this.$Message.error(resp.data.resultDesc);
        this.contents = "";
        this.dataList = [];
        this.resultList1 = [];
        this.resultList = [];
      }
    },
    pictureSave(list) {
      this.dataList = list.map((item) => {
        return {
          accessPath: item.accessPath,
          type: 1,
        };
      });
      this.$Message.success("添加成功");
    },
    resultSave(list) {
      this.resultList = list;
      this.resultList1 = list.map((item) => {
        return JSON.parse(item);
      });
      this.$Message.success("添加成功");
    },
    goDetail() {
      this.$router.push({
        path: "/circledetails",
      });
    },
    async discussDetail(data) {
      const resp = await this.$apis.circleServe.discussDetail(data);
      this.updateObject = resp.data;
      document.title = this.updateObject.title+'-百家在线'
    },
    handleDocument(e) {
      if (e.target.className === "add-picture-container") {
        this.showPicture = false;
      }
      if (e.target.className === "add-results-container") {
        this.showResult = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.discuss-details-container {
  width: 1440px;
  margin: 70px auto;
  .top {
    margin-top: 40px;
    min-height: 250px;
    background: #ffffff;
    border: 1px dotted #999999;
    padding: 30px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #00a4ff;
      padding-bottom: 20px;
      .left {
        display: flex;
        img {
          width: 60px;
          height: 60px;
          margin-right: 15px;
          border-radius: 50%;
        }
        .info {
          .name {
            font-size: 22px;
            color: #444444;
            span {
              margin-right: 6px;
            }
          }
          .update {
            font-size: 16px;
            color: #999999;
          }
        }
      }
      .right {
        font-size: 15px;
        color: #999999;
        .project {
          color: #00a4ff;
          cursor: pointer;
        }
      }
    }
    .title {
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-top: 29px;
      font-size: 24px;
    }
    .content {
      margin-top: 18px;
      font-size: 16px;
      color: #333333;
      text-align: justify;
    }
    .createDate {
      font-size: 14px;
      color: #999999;
      margin-top: 25px;
    }
  }
  .form {
    margin-top: 39px;
    .add-discuss {
      font-size: 24px;
      color: #00a4ff;
    }
    .box {
      width: 1440px;
      height: 200px;
      background: #f6f6f6;
      border-radius: 12px;
      margin: 15px 0;
      ::v-deep .ivu-input-wrapper {
        width: 100%;
        height: 100%;
        .ivu-input {
          width: 100%;
          height: 100%;
          background: #f6f6f6;
          border-radius: 12px;
        }
      }
    }
    .pi-box {
      margin-bottom: 15px;
      .demo-upload-list {
        display: inline-block;
        width: 171px;
        height: 171px;
        text-align: center;
        line-height: 171px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        margin-right: 4px;
      }
      .demo-upload-list img {
        width: 100%;
        height: 100%;
      }
      .demo-upload-list-cover {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
      }
      .demo-upload-list:hover .demo-upload-list-cover {
        display: block;
      }
      .demo-upload-list-cover i {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
      }
      .results-box {
        margin-bottom: 15px;
        .title {
          width: 651px;
          height: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          text-decoration: underline;
          color: #00a4ff;
          cursor: pointer;
        }
      }
    }
    .btns {
      display: flex;
      justify-content: space-between;
      .left {
        .ivu-btn {
          width: 131px;
          height: 38px;
          border: 1px dotted #00a4ff;
          color: #00a4ff;
          margin-right: 10px;
        }
      }
      .right {
        .ivu-btn {
          width: 131px;
          height: 38px;
          background: #00a4ff;
          border-radius: 19px;
        }
      }
    }
  }
  .main {
    width: 1440px;
    margin: 0 auto;
    .nav {
      height: 37px;
      width: 100%;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      li {
        width: 160px;
        text-align: center;
        font-size: 18px;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        .router-link-exact-active.router-link-active {
          color: #00a4ff;
          &::after {
            content: "";
            display: block;
            width: 160px;
            height: 8px;
            background-image: url("~@/assets/imgs/xz.png");
            background-size: 100% 100%;
          }
        }
        a {
          display: inline-block;
          color: #333;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
